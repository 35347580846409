import EmptyOperationData from '../../pages/system/operation-log/operation-data/components/EmptyOperationData';
import CustomerCreated from '../../pages/system/operation-log/operation-data/components/CustomerCreated';
import CustomerChangedVat from '../../pages/system/operation-log/operation-data/components/CustomerChangedVat';
import CustomerPlan from '../../pages/system/operation-log/operation-data/components/CustomerPlan';
import CustomerPlanUpdate from '../../pages/system/operation-log/operation-data/components/CustomerPlanUpdate';
import CustomerCreditCard from '../../pages/system/operation-log/operation-data/components/CustomerCreditCard';
import InvoiceChargeFailed from '../../pages/system/operation-log/operation-data/components/InvoiceChargeFailed';
import InvoiceCharged from '../../pages/system/operation-log/operation-data/components/InvoiceCharged';
import InvoiceCreated from '../../pages/system/operation-log/operation-data/components/InvoiceCreated';
import InvoiceDeleted from '../../pages/system/operation-log/operation-data/components/InvoiceDeleted';
import InvoiceSentByEmail from '../../pages/system/operation-log/operation-data/components/InvoiceSentByEmail';
import InvoiceCreatedNotCharged from '../../pages/system/operation-log/operation-data/components/InvoiceCreatedNotCharged';
import PartnerTermEdited from '../../pages/system/operation-log/operation-data/components/PartnerTermEdited';
import PartnerTermUnlink from '../../pages/system/operation-log/operation-data/components/PartnerTermUnlink';
import ReportView from '../../pages/system/operation-log/operation-data/components/ReportView';
import { OperationLogTypeId } from './OperationLogTypeId';
import { CustomerCreatedOperationLog } from './CustomerCreatedOperationLog';
import { CustomerCancelledPlanChangeLog } from './CustomerCancelledPlanChangeLog';
import { CustomerPlanUpdateOperationLog } from './CustomerPlanUpdateOperationLog';
import { CustomerChangedVatOperationLog } from './CustomerChangedVatOperationLog';
import { InvoiceChargedOperationLog } from './InvoiceChargedOperationLog';
import { InvoiceDeletedOperationLog } from './InvoiceDeletedOperationLog';
import { InvoiceSentByEmailOperationLog } from './InvoiceSentByEmailOperationLog';
import { InvoiceCreatedNotChargedOperationLog } from './InvoiceCreatedNotChargedOperationLog';
import { PartnerTermEditedOperationLog } from './PartnerTermEditedOperationLog';
import { PartnerTermUnlinkOperationLog } from './PartnerTermUnlinkOperationLog';
import { CustomerFulfilledCreditCardOperationLog } from './CustomerFulfilledCreditCardOperationLog';
import { InvoiceChargeFailedOperationLog } from './InvoiceChargeFailedOperationLog';
import { InvoiceCreatedOperationLog } from './InvoiceCreatedOperationLog';
import { ReportSentByEmailOperationLog } from './ReportSentByEmailOperationLog';
import { ReportDeletedOperationLog } from './ReportDeletedOperationLog';
import { OperationLog } from './OperationLog';
import CustomerAnalyticsTier from '../../pages/system/operation-log/operation-data/components/CustomerAnalyticsTier';
import { CustomerRequestedAnalyticsTierChangeOperationLog } from './CustomerRequestAnalyticsTierChangeOperationLog';
import { CustomerChangedPaymentMethodOperationLog } from './CustomerChangedPaymentMethodOperationLog';
import CustomerChangedPaymentMethod from '../../pages/system/operation-log/operation-data/components/CustomerChangedPaymentMethod';
import { CustomerShopifyChangedAccountOperationLog } from './CustomerShopifyChangedAccountOperationLog';
import CustomerShopifyChangedAccount from '../../pages/system/operation-log/operation-data/components/CustomerShopifyChangedAccount';
import { CustomerNameUpdateOperationLog } from './CustomerNameUpdateOperationLog';
import CustomerNameUpdate from '../../pages/system/operation-log/operation-data/components/CustomerNameUpdate';
import { CustomerShopifyRecurringSubscriptionUpdateOperationLog } from './CustomerShopifyRecurringSubscriptionUpdateOperationLog';
import CustomerShopifyRecurringSubscriptionUpdated from '../../pages/system/operation-log/operation-data/components/CustomerShopifyRecurringSubscriptionUpdated';
import { CustomerShopifyUpdatePlanInconsistencyOperationLog } from './CustomerShopifyUpdatePlanInconsistencyOperationLog';
import CustomerShopifyUpdatePlanInconsistency from '../../pages/system/operation-log/operation-data/components/CustomerShopifyUpdatePlanInconsistency';
import { CustomerDeletedOperationLog } from './CustomerDeletedOperationLog';
import CustomerDeleted from '../../pages/system/operation-log/operation-data/components/CustomerDeleted';
import { CustomerCanceledOperationLog } from './CustomerCanceledOperationLog';
import CustomerCanceled from '../../pages/system/operation-log/operation-data/components/CustomerCanceled';

interface IBaseOperationLog<OperationLog> {
    types: OperationLogTypeId[];
    getComponent: (operationLog: OperationLog) => JSX.Element;
}

const CustomerCreatedComponentLog: IBaseOperationLog<CustomerCreatedOperationLog> =
    {
        types: ['customer-created'],
        getComponent: (operationLog: CustomerCreatedOperationLog) =>
            CustomerCreated(operationLog)
    };

const CustomerDeletedComponentLog: IBaseOperationLog<CustomerDeletedOperationLog> =
    {
        types: ['customer-deleted'],
        getComponent: (operationLog: CustomerDeletedOperationLog) =>
            CustomerDeleted(operationLog)
    };

const CustomerCanceledComponentLog: IBaseOperationLog<CustomerCanceledOperationLog> =
    {
        types: ['customer-canceled'],
        getComponent: (operationLog: CustomerCanceledOperationLog) =>
            CustomerCanceled(operationLog)
    };

const CustomerChangedPaymentMethodComponentLog: IBaseOperationLog<CustomerChangedPaymentMethodOperationLog> =
    {
        types: ['customer-changed-payment-method'],
        getComponent: (
            operationLog: CustomerChangedPaymentMethodOperationLog
        ) => CustomerChangedPaymentMethod(operationLog)
    };

const CustomerShopifyChangedAccountComponentLog: IBaseOperationLog<CustomerShopifyChangedAccountOperationLog> =
    {
        types: ['customer-shopify-changed-account'],
        getComponent: (
            operationLog: CustomerShopifyChangedAccountOperationLog
        ) => CustomerShopifyChangedAccount(operationLog)
    };

const CustomerShopifyRecurringSubscriptionUpdatedComponentLog: IBaseOperationLog<CustomerShopifyRecurringSubscriptionUpdateOperationLog> =
    {
        types: ['customer-shopify-recurring-subscription-updated'],
        getComponent: (
            operationLog: CustomerShopifyRecurringSubscriptionUpdateOperationLog
        ) => CustomerShopifyRecurringSubscriptionUpdated(operationLog)
    };

const CustomerShopifyUpdatePlanInconsistencyComponentLog: IBaseOperationLog<CustomerShopifyUpdatePlanInconsistencyOperationLog> =
    {
        types: ['customer-shopify-update-plan-inconsistency'],
        getComponent: (
            operationLog: CustomerShopifyUpdatePlanInconsistencyOperationLog
        ) => CustomerShopifyUpdatePlanInconsistency(operationLog)
    };

const CustomerPlanComponentLog: IBaseOperationLog<CustomerCancelledPlanChangeLog> =
    {
        types: [
            'customer-cancel-plan-change-request',
            'customer-plan-change-request'
        ],
        getComponent: (operationLog: CustomerCancelledPlanChangeLog) =>
            CustomerPlan(operationLog)
    };

const CustomerAnalyticTierComponentLog: IBaseOperationLog<CustomerRequestedAnalyticsTierChangeOperationLog> =
    {
        types: ['customer-analytics-tier-change-request'],
        getComponent: (
            operationLog: CustomerRequestedAnalyticsTierChangeOperationLog
        ) => CustomerAnalyticsTier(operationLog)
    };

const CustomerPlanUpdateComponentLog: IBaseOperationLog<CustomerPlanUpdateOperationLog> =
    {
        types: ['customer-plan-update'],
        getComponent: (operationLog: CustomerPlanUpdateOperationLog) =>
            CustomerPlanUpdate(operationLog)
    };

const CustomerCreditCardComponentLog: IBaseOperationLog<CustomerFulfilledCreditCardOperationLog> =
    {
        types: [
            'customer-fulfilled-credit-card',
            'customer-removed-credit-card'
        ],
        getComponent: (operationLog: CustomerFulfilledCreditCardOperationLog) =>
            CustomerCreditCard(operationLog)
    };

const CustomerChangedVatComponentLog: IBaseOperationLog<CustomerChangedVatOperationLog> =
    {
        types: ['customer-changed-vat'],
        getComponent: (operationLog: CustomerChangedVatOperationLog) =>
            CustomerChangedVat(operationLog)
    };

const InvoiceChargeFailedComponentLog: IBaseOperationLog<InvoiceChargeFailedOperationLog> =
    {
        types: [
            'invoice-charge-failed-notification-failed',
            'invoice-charge-failed'
        ],
        getComponent: (operationLog: InvoiceChargeFailedOperationLog) =>
            InvoiceChargeFailed(operationLog)
    };

const InvoiceChargedComponentLog: IBaseOperationLog<InvoiceChargedOperationLog> =
    {
        types: ['invoice-charged'],
        getComponent: (operationLog: InvoiceChargedOperationLog) =>
            InvoiceCharged(operationLog)
    };

const InvoiceCreatedComponentLog: IBaseOperationLog<InvoiceCreatedOperationLog> =
    {
        types: ['invoice-created', 'invoice-emitted'],
        getComponent: (operationLog: InvoiceCreatedOperationLog) =>
            InvoiceCreated(operationLog)
    };

const InvoiceDeletedComponentLog: IBaseOperationLog<InvoiceDeletedOperationLog> =
    {
        types: ['invoice-deleted'],
        getComponent: (operationLog: InvoiceDeletedOperationLog) =>
            InvoiceDeleted(operationLog)
    };

const InvoiceSentByEmailComponentLog: IBaseOperationLog<InvoiceSentByEmailOperationLog> =
    {
        types: ['invoice-sent-by-email'],
        getComponent: (operationLog: InvoiceSentByEmailOperationLog) =>
            InvoiceSentByEmail(operationLog)
    };

const InvoiceCreatedNotChargedComponentLog: IBaseOperationLog<InvoiceCreatedNotChargedOperationLog> =
    {
        types: ['invoice-created-not-charged'],
        getComponent: (operationLog: InvoiceCreatedNotChargedOperationLog) =>
            InvoiceCreatedNotCharged(operationLog)
    };

const ReportComponentLog: IBaseOperationLog<ReportSentByEmailOperationLog> = {
    types: [
        'report-sent-by-email',
        'report-generated',
        'report-edited',
        'report-set-resolved-status',
        'report-deleted'
    ],
    getComponent: (operationLog: ReportSentByEmailOperationLog) =>
        ReportView(operationLog)
};

const PartnerTermEditedComponentLog: IBaseOperationLog<PartnerTermEditedOperationLog> =
    {
        types: ['partner-term-edited'],
        getComponent: (operationLog: PartnerTermEditedOperationLog) =>
            PartnerTermEdited(operationLog)
    };

const PartnerTermUnlinkComponentLog: IBaseOperationLog<PartnerTermUnlinkOperationLog> =
    {
        types: ['partner-term-unlink'],
        getComponent: (operationLog: PartnerTermUnlinkOperationLog) =>
            PartnerTermUnlink(operationLog)
    };

const CustomerNameUpdateComponentLog: IBaseOperationLog<CustomerNameUpdateOperationLog> =
    {
        types: ['customer-name-update'],
        getComponent: (operationLog: CustomerNameUpdateOperationLog) =>
            CustomerNameUpdate(operationLog)
    };

type OperationLogTypes =
    | IBaseOperationLog<CustomerCreatedOperationLog>
    | IBaseOperationLog<CustomerChangedPaymentMethodOperationLog>
    | IBaseOperationLog<CustomerCancelledPlanChangeLog>
    | IBaseOperationLog<CustomerPlanUpdateOperationLog>
    | IBaseOperationLog<CustomerFulfilledCreditCardOperationLog>
    | IBaseOperationLog<CustomerChangedVatOperationLog>
    | IBaseOperationLog<InvoiceChargeFailedOperationLog>
    | IBaseOperationLog<InvoiceChargedOperationLog>
    | IBaseOperationLog<InvoiceCreatedOperationLog>
    | IBaseOperationLog<InvoiceDeletedOperationLog>
    | IBaseOperationLog<InvoiceSentByEmailOperationLog>
    | IBaseOperationLog<InvoiceCreatedNotChargedOperationLog>
    | IBaseOperationLog<ReportSentByEmailOperationLog>
    | IBaseOperationLog<ReportDeletedOperationLog>
    | IBaseOperationLog<PartnerTermEditedOperationLog>
    | IBaseOperationLog<PartnerTermUnlinkOperationLog>
    | IBaseOperationLog<CustomerRequestedAnalyticsTierChangeOperationLog>
    | IBaseOperationLog<CustomerShopifyChangedAccountOperationLog>
    | IBaseOperationLog<CustomerNameUpdateOperationLog>
    | IBaseOperationLog<CustomerShopifyRecurringSubscriptionUpdateOperationLog>
    | IBaseOperationLog<CustomerShopifyUpdatePlanInconsistencyOperationLog>
    | IBaseOperationLog<CustomerDeletedOperationLog>
    | IBaseOperationLog<CustomerCanceledOperationLog>;

export class OperationDataFactory {
    private static operationLogs: OperationLogTypes[] = [
        CustomerCreatedComponentLog,
        CustomerPlanComponentLog,
        CustomerPlanUpdateComponentLog,
        CustomerCreditCardComponentLog,
        CustomerChangedVatComponentLog,
        CustomerAnalyticTierComponentLog,
        InvoiceChargeFailedComponentLog,
        InvoiceChargedComponentLog,
        InvoiceCreatedComponentLog,
        InvoiceDeletedComponentLog,
        InvoiceSentByEmailComponentLog,
        InvoiceCreatedNotChargedComponentLog,
        ReportComponentLog,
        PartnerTermEditedComponentLog,
        PartnerTermUnlinkComponentLog,
        CustomerChangedPaymentMethodComponentLog,
        CustomerShopifyChangedAccountComponentLog,
        CustomerNameUpdateComponentLog,
        CustomerShopifyRecurringSubscriptionUpdatedComponentLog,
        CustomerShopifyUpdatePlanInconsistencyComponentLog,
        CustomerDeletedComponentLog,
        CustomerCanceledComponentLog
    ];

    static getComponent(
        type: string
    ): (operationLog: OperationLog) => JSX.Element {
        const operationLog = this.operationLogs.find(opLog =>
            opLog.types.some(t => t === type)
        );
        if (!operationLog) {
            return EmptyOperationData;
        }
        return operationLog.getComponent as (
            operationLog: OperationLog
        ) => JSX.Element;
    }
}
