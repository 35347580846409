import React from 'react';
import { Link } from 'react-router-dom';
import { CustomerCanceledOperationLog } from '../../../../../models/system/CustomerCanceledOperationLog';

const CustomerCanceled = (operationLog: CustomerCanceledOperationLog) => (
    <div>
        <p>
            Account name:{' '}
            <Link to={`/customer/${operationLog.customerUUID}/edit`}>
                {operationLog.customerName}
            </Link>{' '}
        </p>
    </div>
);

export default CustomerCanceled;
